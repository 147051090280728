<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
      >
        <permissions-list
          :permissions-groups="permissionsGroups"
          permissions-getter="hasWorkspacePermission"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PermissionsList from '@/components/lists/PermissionsList.vue'

export default {
  name: 'WorkspacePermissions',
  components: {
    PermissionsList
  },
  data () {
    return {
      permissionsGroups: [
        {
          group: 'permissions.groups.documents',
          permissions: [
            { value: 'view_documents_document', text: 'permissions.view.document', icon: 'remove_red_eye' },
            { value: 'view_documents_documentpdf', text: 'permissions.view.documentPdf', icon: 'remove_red_eye' },
            { value: 'view_documents_attachment', text: 'permissions.view.documentAttachment', icon: 'remove_red_eye' },
            { value: 'view_documents_email', text: 'permissions.view.email', icon: 'remove_red_eye' },
            { value: 'add_documents_attachment', text: 'permissions.add.documentAttachment', icon: 'add' },
            { value: 'add_documents_receivedfile', text: 'permissions.add.document', icon: 'add' },
            { value: 'add_documents_receivedfile', text: 'permissions.add.documentCopy', icon: 'flip' },
            { value: 'add_documents_receivedfile', text: 'permissions.add.documentFlip', icon: 'flip' },
            { value: 'delete_documents_document', text: 'permissions.delete.document', icon: 'delete' },
            { value: 'delete_documents_attachment', text: 'permissions.delete.documentAttachment', icon: 'delete' }
          ]
        },
        {
          group: 'permissions.groups.workspaceSnippets',
          permissions: [
            { value: 'view_users_webformsnippet', text: 'permissions.view.snippets', icon: 'remove_red_eye' },
            { value: 'add_users_webformsnippet', text: 'permissions.add.snippets', icon: 'add' },
            { value: 'change_users_webformsnippet', text: 'permissions.edit.snippets', icon: 'edit' },
            { value: 'delete_users_webformsnippet', text: 'permissions.delete.snippets', icon: 'delete' }
          ]
        },
        {
          group: 'permissions.groups.pipelines',
          permissions: [
            { value: 'view_pipelines_pipeline', text: 'permissions.view.pipeline', icon: 'remove_red_eye' },
            { value: 'view_pipelines_stepmessage', text: 'permissions.view.messages', icon: 'remove_red_eye' }
          ]
        },
        {
          group: 'permissions.groups.sentFiles',
          permissions: [
            { value: 'view_documents_receivedfile', text: 'permissions.view.sentFile', icon: 'remove_red_eye' }
          ]
        },
        {
          group: 'permissions.groups.integrationFiles',
          permissions: [
            { value: 'view_documents_integrationfile', text: 'permissions.view.integrationFile', icon: 'remove_red_eye' }
          ]
        },
        {
          group: 'permissions.groups.reports',
          permissions: [
            { value: 'view_users_documentreport', text: 'permissions.view.report', icon: 'remove_red_eye' },
            { value: 'add_users_documentreport', text: 'permissions.add.report', icon: 'remove_red_eye' },
            { value: 'delete_users_documentreport', text: 'permissions.delete.report', icon: 'remove_red_eye' }
          ]
        },
        {
          group: 'permissions.groups.schedules',
          permissions: [
            { value: 'view_users_reportschedule', text: 'permissions.view.schedule', icon: 'remove_red_eye' },
            { value: 'add_users_reportschedule', text: 'permissions.add.schedule', icon: 'remove_red_eye' },
            { value: 'change_users_reportschedule', text: 'permissions.edit.schedule', icon: 'remove_red_eye' },
            { value: 'delete_users_reportschedule', text: 'permissions.delete.schedule', icon: 'remove_red_eye' }
          ]
        }
      ]
    }
  }
}
</script>
