<template>
  <card-layout disable-title>
    <template v-slot:content>
      <v-row>
        <v-col
          v-for="permission of permissionsGroups"
          :key="permission.group"
          cols="12"
          sm="6"
          md="4"
          class="px-0"
        >
          <v-subheader class="text-uppercase blue-grey--text text--darken-3">
            {{ $t(permission.group) }}
          </v-subheader>
          <v-data-iterator
            :items="permission.permissions"
            hide-default-footer
          >
            <template v-slot:item="{ item }">
              <v-list-item
                dense
                :disabled="!hasPermission(item.value)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon
                    :color="getIconColor(item)"
                    :disabled="!hasPermission(item.value)"
                  >
                    {{ getIcon(item) }}
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
          </v-data-iterator>
        </v-col>
      </v-row>
    </template>
  </card-layout>
</template>

<script>
import CardLayout from '@/components/layout/CardLayout'
import { mapGetters } from 'vuex'

export default {
  name: 'PermissionsList',
  components: {
    CardLayout
  },
  props: {
    permissionsGroups: { type: Array, default: () => { return [] } },
    permissionsGetter: { type: String, default: '' }
  },
  computed: {
    ...mapGetters([
      'hasWorkspacePermission',
      'hasClientPermission'
    ])
  },
  methods: {
    hasPermission (permission) {
      return this[this.permissionsGetter](permission)
    },
    getIcon (item) {
      return this[this.permissionsGetter](item.value)
        ? this.$vuetify.icons.values.check
        : this.$vuetify.icons.values.lockOutline
    },
    getIconColor (item) {
      return this[this.permissionsGetter](item.value)
        ? 'success'
        : ''
    }
  }
}
</script>
